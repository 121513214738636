<template>
    <div class="row justify-content-center">
        <div class="col-lg-12">
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                <ul class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-between product-info-tab border-bottom-0" id="pills-tab" role="tablist">
                    <li :class="{'active' : tab == '#discussion'}" class="list-inline-item"><a :class="{'active' : tab == '#discussion'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 ml-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#discussion">Form-focused Task</a></li>
                    <li :class="{'active' : tab == '#quiz'}" class="list-inline-item"><a :class="{'active' : tab == '#quiz'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#quiz">Quiz</a></li>
                    <li :class="{'active' : tab == '#individual'}" class="list-inline-item"><a :class="{'active' : tab == '#individual'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#individual">Individual Assignment</a></li>
                    <li :class="{'active' : tab == '#group'}" class="list-inline-item"><a :class="{'active' : tab == '#group'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#group">Group Assignment</a></li>
                    <li :class="{'active' : tab == '#achievement'}" class="list-inline-item"><a :class="{'active' : tab == '#achievement'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 mr-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#achievement">Achievement Test</a></li>
                </ul>
            </div>
            <div class="row">
                <div class="col-12" v-if="tab == '#discussion'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row justify-content-around">
                            <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Form-focused Task</b></h2></div>
                            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2">
                                <router-link :to="{name: 'AssignmentsDiscussionCreateCoursesAdmin', params: {idCourse: paramsId}}" class="w-100 d-block btn bg-current text-white font-xssss fw-700 ls-3 style1-input p-3 border-0 text-uppercase ">
                                    <i class="fas fa-plus" style="margin-right: 5px;"></i>
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <discussion></discussion>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-if="tab == '#quiz'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row justify-content-around">
                            <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Quiz</b></h2></div>
                            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2">
                                <router-link :to="{name: 'AssignmentsQuizCreateCoursesAdmin', params: {idCourse: paramsId}}" class="w-100 d-block btn bg-current text-white font-xssss fw-700 ls-3 style1-input p-3 border-0 text-uppercase ">
                                    <i class="fas fa-plus" style="margin-right: 5px;"></i>
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <quiz></quiz>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-if="tab == '#individual'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row justify-content-around">
                            <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Individual Assignment</b></h2></div>
                            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2">
                                <router-link :to="{name: 'AssignmentsIndividualCreateCoursesAdmin', params: {idCourse: paramsId}}" class="w-100 d-block btn bg-current text-white font-xssss fw-700 ls-3 style1-input p-3 border-0 text-uppercase ">
                                    <i class="fas fa-plus" style="margin-right: 5px;"></i>
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <individual></individual>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-if="tab == '#group'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row justify-content-around">
                            <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Group Assignment</b></h2></div>
                            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2">
                                <router-link :to="{name: 'AssignmentsGroupCreateCoursesAdmin', params: {idCourse: paramsId}}" class="w-100 d-block btn bg-current text-white font-xssss fw-700 ls-3 style1-input p-3 border-0 text-uppercase ">
                                    <i class="fas fa-plus" style="margin-right: 5px;"></i>
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <group></group>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-if="tab == '#achievement'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row justify-content-around">
                            <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Achievement Test</b></h2></div>
                            <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2">
                                <router-link :to="{name: 'AssignmentsAchievementCreateCoursesAdmin', params: {idCourse: paramsId}}" class="w-100 d-block btn bg-current text-white font-xssss fw-700 ls-3 style1-input p-3 border-0 text-uppercase ">
                                    <i class="fas fa-plus" style="margin-right: 5px;"></i>
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <achievement></achievement>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Discussion from './Sub/Discussion/Discussion.vue'
import Achievement from './Sub/Achievement/Achievement.vue'
import Quiz from './Sub/Quiz/Quiz.vue'
import Individual from './Sub/Individual/Individual.vue'
import Group from './Sub/Group/Group.vue'
export default {
    components: {
        Discussion,
        Achievement,
        Quiz,
        Individual,
        Group
    },
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            tab: this.$route.hash ? this.$route.hash : '#discussion'
        }
    }
}
</script>
