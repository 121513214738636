<template>
    <div>
        <div class="row" v-if="isFound">
            <div class="col-12">
                <div class="row" v-if="!isLoad">
                    <div  class="col-12" v-if="assignment.length">
                        <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter" @keyup="searchTable()">
                    </div>
                    <div class="col-12 text-center py-3" v-if="assignment.length">
                        <div class="table-responsive mw-100 pr-2 ml-0">
                            <table class="table table-hover table-bordered" id="custom-table">
                                <thead>
                                    <tr>
                                        <th class="border-0 bg-current text-white p-3" scope="col" colspan="2">No</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">Name</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">Submission</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">Created By</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">Due Date</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">Syllabus</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">Cycle - Unit</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">Publish</th>
                                        <th class="border-0 bg-current text-white p-3" scope="col">Option</th>
                                    </tr>
                                </thead>
                                <draggable v-model="assignment" tag="tbody" handle=".handle" :disabled="!drag" @end="reOrder()">
                                <tr v-for="(row, index) in assignment" :key="index">
                                    <td><a href="javascript:void(0)"><i class="fas fa-bars handle"></i></a></td>
                                    <td>{{ index+1 }}</td>
                                    <td>{{ row.title }}</td>
                                    <td>
                                        <router-link class="btn btn-sm btn-primary mr-2" :to="{name: 'AssignmentsIndividualSubmissionListCoursesAdmin', params: {idCourse: paramsId, idAssignment: row.id}}">{{row.submission}} Students</router-link>
                                    </td>
                                    <td>{{ row.created_by?.name || '-' }}</td>
                                    <td>{{ row.due_date | formatDate}}</td>
                                    <td>{{ row.syllabus.content }}</td>
                                    <td>Cycle {{ row.unit.cycle }} - {{ row.unit.content }}</td>
                                    <td>
                                        <button @click="changeStatusAlert(row.id)" class="btn btn-sm" :class="{'btn-success' : row.publish, 'btn-danger' : !row.publish }"><i class="fas" :class="{'fa-check' : row.publish, 'fa-times' : !row.publish }"></i></button>
                                    </td>
                                    <td>
                                        <router-link class=" mr-2" :to="{name: 'AssignmentsIndividualTaskCoursesAdmin', params: {idCourse: paramsId, idAssignment: row.id}}"><i class="fas fa-info text-info"></i></router-link>
                                        <router-link class=" mr-2" :to="{name: 'AssignmentsIndividualEditCoursesAdmin', params: {idCourse: paramsId, idAssignment: row.id}}"><i class="fas fa-edit text-warning"></i></router-link>
                                        <a href="javascript:void(0)" @click="deleteAlert(row.id)" class=""><i class="fas fa-trash text-danger"></i></a>
                                    </td>
                                </tr>
                                </draggable>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 text-center" v-if="!assignment.length">
                        <img :src="'/images/empty.png'" alt="" width="300">
                        <h1 class="text-muted">Data don't exist</h1>
                    </div>
                </div>
                <div v-if="isLoad || isStillLoad" class="row">
                    <div class="col-12 text-center py-3">
                        <div class="my-3">
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="vertical-wrapper pt-lg--7 pt-3">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-8 text-center default-page">
                            <div class="card border-0 text-center d-block">
                                <img :src="'/images/404.png'" alt="icon" class="w300 mb-4 ml-auto mr-auto ">
                                <h1 class="fw-700 text-grey-900 display4-size display4-md-size">Page Not Found.</h1>
                                <p class="text-grey-500 font-xss">The page you're looking for isn't available. Try to search again or use the go to.</p>
                                <button @click="$router.go(-1)" class="p-3 border-0 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3">Return Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import draggable from 'vuedraggable'
export default {
    components: {
        draggable
    },
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            assignment: [
                {
                    id: 1,
                    title: 'First Individual',
                    submission: '10 / 30',
                    description: 'Hello there!',
                    publish: true,
                    due_date: '2021-10-05T01:00',
                    created_by: 'Administrator',
                    created_at: '2021-10-05 01:00:00'
                }
            ],
            isLoad: true,
            isStillLoad: true,
            isFound: true,
            tableFilter: '',
            drag: true
        }
    },
    filters:{
        datetime: function (date) {
            return moment(date).calendar();
        }
    },
    created(){
        this.getAssignment()
    },
    methods:{
        async getAssignment(){
            let queryLimit = 10;
            let queryOffset = 0;
            this.assignment = [];

            while(this.isStillLoad){
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/assignment/v2?type=3&slug=${this.paramsId}&limit=${queryLimit}&offset=${queryOffset}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    if(res.data.data.length == 0){
                        this.isStillLoad = false
                        this.isLoad = false
                    }else{
                        this.assignment = [...this.assignment, ...res.data.data];
                        queryOffset = queryOffset + queryLimit
                        this.isLoad = false
                    }
                }).catch(err => {
                    if (err.response.status == 404) {
                        this.isFound = false
                    }
                })
            }
            
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then(async (result) => {
                if(result.value) {
                    var data = {
                        _method: 'delete',
                        slug: this.paramsId,
                        id: id
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/assignment`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.$swal({
                            toast: true,
                            title: 'Assignments - Individual !',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.getAssignment()
                    }).catch(err => {
                        if (err.response.status == 422) {
                            this.$swal({
                                toast: true,
                                title: 'Assignments - Individual !',
                                text: err.response.data.message[0],
                                icon: 'error',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                        }
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        changeStatusAlert(id){
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Change Status it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then(async (result) => {
                if(result.value) {
                    var data = {
                        slug: this.paramsId,
                        id: id
                    }
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/assignment/publish`, data, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }).then(res => {
                        this.$swal({
                            toast: true,
                            title: 'Assignments - Individual !',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.getAssignment()
                    }).catch(err => {
                        if (err.response.status == 422) {
                            this.$swal({
                                toast: true,
                                title: 'Assignments - Individual !',
                                text: err.response.data.message[0],
                                icon: 'error',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                        }
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        searchTable(){
            var input, table, tr, td, i, j, txtValue, show;
            input = this.tableFilter.toLowerCase();
            table = document.getElementById("custom-table");
            tr = table.querySelectorAll('tbody tr')
            for (i = 0; i < tr.length; i++) {
                show = false
                td = tr[i].getElementsByTagName("td");
                if (td.length > 0) {
                    for(j = 0; j < td.length; j++) {
                        txtValue = td[j].textContent || td[j].innerText;
                        if (txtValue.toLowerCase().indexOf(input) > -1) {
                            show = true
                        }
                    }
                }
                tr[i].style.display = !show ? "none" : "";
            }

        },
        async reOrder() {
            this.drag = false
            var data ={
                assignments: this.assignment,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/assignment/reorder`, data,  {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'Individual Assignment',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.drag = true;
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Individual Assignment',
                    text: 'Failed Reorder!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.getAssignment();
                this.drag = true;
            })
        },
    }
}

</script>